var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "clearfix", staticStyle: { "min-height": "500px" } },
      [
        _c("div", {
          staticStyle: { background: "#ffffff" },
          attrs: { id: "wangEditorElem" }
        })
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }