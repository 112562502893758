var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "AddContractdetail" },
    [
      _c("app-header", { attrs: { title: _vm.title, isShowBack: true } }),
      !_vm.showHide
        ? _c(
            "el-form",
            { attrs: { "label-width": "150px" } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "合同模板名称" } },
                [
                  _c("el-input", {
                    attrs: { maxlength: "50" },
                    model: {
                      value: _vm.ContractName,
                      callback: function($$v) {
                        _vm.ContractName = $$v
                      },
                      expression: "ContractName"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "合同条款" } },
                [
                  _c("WE", {
                    ref: "we",
                    attrs: { content: this.defaultContent }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      staticStyle: { width: "180px", "margin-bottom": "20px" },
                      attrs: { type: "primary" },
                      on: {
                        click: function($event) {
                          _vm.AddForm()
                        }
                      }
                    },
                    [_vm._v("保存")]
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm.showHide
        ? _c(
            "el-form",
            { attrs: { "label-width": "150px" } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "合同模板名称" } },
                [
                  _c("el-input", {
                    attrs: { disabled: "disabled" },
                    model: {
                      value: _vm.ContractNamelit,
                      callback: function($$v) {
                        _vm.ContractNamelit = $$v
                      },
                      expression: "ContractNamelit"
                    }
                  })
                ],
                1
              ),
              _c("el-form-item", { attrs: { label: "合同条款" } }, [
                _c("div", {
                  staticClass: "detail",
                  domProps: { innerHTML: _vm._s(_vm.defaultContentlist) }
                })
              ])
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }